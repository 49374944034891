import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wlx-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() items = new Array<BasketItem>();
}

export type BasketItem = {
  name: string;
  category: string;
  amount: number;
  imageSrc: string;
  centsPerUnit: number;
};
